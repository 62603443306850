import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import RotatingImage from './mod/RotatingImage';
import './mod/RotatingImage.css'; // Подключение стилей

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Quiz />} />
            </Routes>
        </BrowserRouter>
    );
}

const Quiz = () => {
    const [quest, setQuest] = useState('');
    const [ans, setAns] = useState([]);
    //index of selected answer
    const [reply, setReply] = useState(0);
    const [started, setStarted] = useState(false);
    const [lost, setLost] = useState(false);
    const [score, setScore] = useState(0);
    const [scored, setScored] = useState(0);
    const [record, setRecord] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const sessionId = searchParams.get('sessionId');

    const [newSessionId, setNewSessionId] = useState(sessionId);
    const [seconds, setSeconds] = useState(10);

    const resetTimer = () => {
        setSeconds(10);
    };

    useEffect(() => {
        let timer;
        if (started && seconds > 0) {
            timer = setTimeout(() => setSeconds(prevSeconds => prevSeconds - 1), 1000);
        } else if (seconds === 0) {
            setLost(true);
            setStarted(false);
        }
        return () => clearTimeout(timer);
    }, [seconds, started]);

    useEffect(() => {
        // Добавление нового параметра
        searchParams.set('sessionId', newSessionId);

        // Удаление параметра
        // searchParams.delete('старый_параметр');

        // Применяем обновленные параметры к URL
        window.history.replaceState(null, null, `?${searchParams.toString()}`);
    }, [newSessionId, searchParams]);

    const handleAnswer = (selectedAnswer, answerIndex) => {
        //console.log(selectedAnswer, answerIndex);
        setReply(answerIndex);
        // setScore(score + 1);
        fetchQuiz(newSessionId, answerIndex);
    };

    const fetchQuiz = (sessionId, reply) => {
        const requestData = {
            sessionId: sessionId,
            score: score,
            answer: reply
        };

        //console.log("send", requestData);
        //fetch('http://localhost:8787/game/quiz', {
        fetch('https://bstrdn1.fvds.ru/game/quiz', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => {
                if (response.ok) {
                    // Обработка успешного ответа
                    return response.json();
                } else if (response.status === 400) {
                    throw new Error('Bad Request');
                } else {
                    throw new Error('Unexpected error');
                }
            })
            .then(data => {
                //console.log(data);
                if (data) {
                    setQuest(data.quest || '');
                    setAns(data.answers || []);
                    setScore(data.scor || 0);
                    setScored(data.scor || 0);
                    if (data.rec > 0) {
                        setRecord(data.rec);
                    }
                    setNewSessionId(data.sessionId || '');
                    resetTimer(); // Сброс таймера после правильного ответа
                }
            })
            .catch(error => {
                //console.log(error);
                setLost(true);
                setScored(score);
                setScore(0);
                setStarted(false);
            });
    };

    const handleStart = () => {
        setLost(false);
        setStarted(true);
        resetTimer();
        fetchQuiz(newSessionId);
    };

    function Score() {
        return (
            <div className="d-flex justify-content-between">
                <div className="score">
                    Score: {score}
                </div>
                <div className="record">
                    Record: {record}
                </div>
            </div>
        );
    }

    function Scored() {
        return (
            <div>
                <div className="scored">
                    <p style={{ fontWeight: 'bold' }}>You scored: {scored} vrot</p>
                    <p>The last sentence: {quest}</p>
                </div>
            </div>
        );
    }

    if (sessionId === null || sessionId === undefined || sessionId === '' || sessionId === 'null') {
        return (
            <div>
                <RotatingImage />
            </div>
        );
    }

    if (lost) {
        return (
            <Container className="bg-light text-center p-5 d-flex flex-column justify-content-between"
                style={{ height: '500px', width: '400px' }}>
                <Score />
                <Scored />
                <Container className="p-5">
                    <Button variant="danger" onClick={handleStart} className="mt-auto w-50 mx-auto">Restart</Button>
                </Container>
            </Container>
        );
    }

    if (!started) {
        return (
            <Container className="bg-light text-center p-5 d-flex flex-column justify-content-between"
                style={{ height: '500px', width: '400px' }}>
                <Score />
                <Container className="p-5">
                    <Button variant="success" onClick={handleStart} className="mt-auto w-50 mx-auto">Start</Button>
                </Container>
            </Container>
        );
    }
    // resetTimer();
    return (
        // <Container className="bg-light text-center"  style={{ height: '500px', width: '400px' }}>
        // <Score/>

        <Container className="bg-light text-center p-5 d-flex flex-column justify-content-between"
                   style={{height: '500px', width: '400px'}}>
            <Score/>
            <div>
                <p>Time left: {seconds} seconds</p>
            </div>
            <p style={{fontWeight: 'bold'}}>Who BPOT this?</p>
            <p className="question">{quest}</p>


            <div className="button-container">
                {ans.length > 0 ? ans.map((option, index) => (
                    <Button
                        variant="outline-primary"
                        onClick={() => handleAnswer(option, index)}
                        key={index}
                        className="mx-2"
                    >
                        {option}
                    </Button>
                )) : <p>No options available</p>}
            </div>
            {/*<div className="d-flex justify-content-center">*/}
            {/*    {ans.length > 0 ? ans.map((option, index) => (*/}
            {/*        <Button variant="outline-primary" onClick={() => handleAnswer(option, index)} key={index}*/}
            {/*            className="mx-2">*/}
            {/*            {option}*/}
            {/*        </Button>*/}
            {/*    )) : <p>No options available</p>}*/}
            {/*</div>*/}
            {/*</Container>*/}
            {/*<button onClick={() => fetchQuiz(sessionId, tgInl)}>Новый вопрос</button>*/}
        </Container>
    );
};

export default App;