import React from 'react';
import './RotatingImage.css'; // Подключение стилей
import logo from '../img/rot.png';

const RotatingImage = () => {
    return (
        <div className="rotating-image-container">
            <img
                src={logo}
                alt="vrt"
                className="rotating-image"
            />
        </div>
    );
};

export default RotatingImage;